<template>
  <div class="desktop-dashboard-setup is-flex is-justify-content-center is-align-content-center is-flex-direction-column">
    <div class="setup-title has-text-centered has-text-weight-semibold">
      Set-up your account
    </div>
    <div class="setup-content">
      <div :class="`step step${index + 1}`" v-for="(step, index) in steps" :key="step.stepName">
        <div class="step-index has-text-blue has-text-weight-semibold" v-if="step.stepName !== 'filler'">STEP {{index + 1}}</div>
        <div class="step-content">
          <div :class="`step-title has-text-weight-semibold ${step.status}`" @click="toStep(step, 'desktop')">{{step.title}}</div>
          <div class="step-status">
            <i :class="`status-icon ${step.status} is-inline-block`" @click="toStep(step, 'desktop')"></i>
          </div>
        </div>
      </div>
    </div>
    <IdVerificationModal
      :showModal="showVerificationModal"
      :closeModal="() => { showVerificationModal = false }"
      :governmentIdStatus="governmentIdStatus"
      :investorOnVeriffWatchlist="investorOnVeriffWatchlist"
      :useVeriff="isVeriffEnabled"
    >
    </IdVerificationModal>
    <div class="addySign-container" v-if="showAddySignModal">
      <button class="modal-close is-large" @click="toggleAddySignModal(false)"></button>
      <iframe :src="addySignUrl" title="Confidentiality Agreement" id="addySign-iframe"></iframe>
    </div>
  </div>
</template>

<script>
import IdVerificationModal from '@components/modal/id-verification-modal.vue'
import signConfidentialityAgreement from '@utils/mixins/sign-confidentiality-agreement.js'
import walletRoutes from '@utils/mixins/wallet-routes'
import dashboardCommonMethods from '@utils/mixins/dashboard-common-methods'

export default {
  mixins: [signConfidentialityAgreement, walletRoutes, dashboardCommonMethods],
  components: {
    IdVerificationModal,
  },
  props: {
    profileSteps: {
      type: Array,
      required: true
    },
    governmentIdStatus: {
      type: String,
      required: true
    },
    hasAcceptedConfidentiality: {
      type: Boolean,
      required: true
    },
    investorOnVeriffWatchlist: {
      type: Boolean,
      required: true
    },
  },
}
</script>

<style lang="scss" scoped>
.desktop-dashboard-setup {
  padding: 20px 50px;
  flex-wrap: wrap;
  .setup-title {
    font-size: 16px;
    padding-bottom: 5vh;
  }
  .setup-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .step {
      flex-direction: row;
      margin-bottom: 30px;
      &:nth-child(odd) {
        margin-right: 20px;
      }
      .step-index {
        font-size: 12px;
        padding-bottom: 5px;
      }
      .step-content {
        display: flex;
        .step-title {
          width: 220px;
          min-width: 220px;
          font-size: 18px;
          &.TO-DO:hover,
          &.failed:hover,
          &.out-of-area:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
        .step-status {
          .status-icon {
            width: 12px;
            height: 12px;
            &.complete {
              background: url('../../../assets/icons/check.svg') center/contain no-repeat;
            }
            &.TO-DO {
              background: url('../../../assets/icons/arrow-right.svg') center/contain no-repeat;
              cursor: pointer;
            }
            &.pending,
            &.processing {
              width: 14px;
              height: 14px;
              background: url('../../../assets/icons/pending-blue.svg') center/contain no-repeat;
            }
            &.failed {
              width: 14px;
              height: 14px;
              background: url('../../../assets/icons/info.svg') center/contain no-repeat;
              cursor: pointer;
            }
            &.out-of-area {
              background: url('../../../assets/icons/lock.svg') center/contain no-repeat;
              cursor: pointer;
            }
          }
        }
      }
    }
    .step1 {
      .step-title {
        &.pending:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
      .status-icon {
        &.pending:hover {
          cursor: pointer;
        }
      }
    }
  }
  .addySign-container {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .modal-close {
      top: 8px;
      left: 6px;
      &::before,
      &::after {
        background-color: #4A26AA;
      }
    }
    #addySign-iframe {
      width: 100%;
      height: 100%;
    }
  }
  .verification-modal-text {
    font-size: 16px;
    line-height: 19px;
  }
}

@media (min-width:1450px) {
  .desktop-dashboard-setup {
    .step .step-content .step-status {
      margin-left: 20px;
    }
  }
}
@media (max-width:1320px) {
  .desktop-dashboard-setup {
    padding: 20px;
  }
}
@media (max-width:1250px) {
  .desktop-dashboard-setup .setup-content .step:nth-child(odd) {
    margin-right: 0px;
  }
}
// under 1270px width Portfolio component will have problems
</style>
