<template>
  <div class="desktop-dashboard-wallet">
    <WalletCard :data="walletBalanceCard" @switchWallet="switchWallet">
      <div class="add-fund is-flex is-justify-content-center is-align-items-center is-clickable" @click="toAddFundsPage">
        <img src="@assets/icons/plus.svg" alt="add">
      </div>
    </WalletCard>
    <div class="transactions-wrap">
      <div class="section-title has-text-weight-semibold">Recent activity</div>
      <Transactions :fromDesktopDashboard="true" :walletCountry="walletCountry" ref="walletTransactionsComponent"></Transactions>
    </div>
  </div>
</template>

<script>
import walletRoutes from '@utils/mixins/wallet-routes.js'
import WalletCard from '@views/dashboard/WalletCard.vue'
import Transactions from '@views/wallet/landing/Transactions.vue'
import walletCurrencySwitch from '@utils/mixins/wallet-currency-switch-mixin'

export default {
  mixins: [walletRoutes, walletCurrencySwitch],
  components: {
    WalletCard,
    Transactions,
  },
  data() {
    return {
      walletCountry: 'CA',
      walletBalanceCard: {
        walletBalance: 0,
        addyHandle: '',
        firstName: '',
        lastName: '',
        processingDepositTotal: 0,
        isLoading: false,
        walletCurrency: 'CAD',
      },
    }
  },
  props: {
    walletBalanceData: {
      type: Object,
      required: true,
    }
  },
  watch: {
    walletBalanceData() {
      this.initWalletData()
    },
  },
  created() {
    this.initInvestorData()
  },
  methods: {
    initInvestorData() {
      const investor = JSON.parse(localStorage.getItem('investor'))
      this.walletBalanceCard.firstName = investor.firstName
      this.walletBalanceCard.lastName = investor.lastName
      this.walletBalanceCard.addyHandle = investor.addyHandle
    },
    initWalletData() {
      this.walletBalanceCard.walletCurrency = this.walletBalanceData.walletCurrency
      this.walletBalanceCard.walletBalance = this.walletBalanceData.walletBalance
      this.walletBalanceCard.processingDepositTotal = this.walletBalanceData.processingDepositTotal
    },
  },
}
</script>

<style lang="scss">
.desktop-dashboard-wallet {
  .wallet-card {
    padding: 20px;
  }
  .add-fund {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 8px;
    right: 50px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.5);
  }
  .transactions-wrap {
    padding: 20px 20px 30px;
    .section-title {
      font-size: 16px;
    }
    .transaction-history{
      margin-top: 20px;
      .transaction-block {
        padding: 5px;
        min-height: 60px;
        margin-bottom: 16px;
        border-radius: 12px;
        background-color: white;
        .icon {
          background-color: white;
          &.del img {
            opacity: 0.5;
          }
        }
        .details {
          padding-left: 10px;
          .transaction-name {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
