<template>
  <div class="desktop-dashboard-blogs has-text-left">
    <div class="swiper-box is-flex is-flex-direction-column is-justify-content-space-between">
      <div class="blogs-swiper-container is-clickable">
        <div class="swiper-wrapper">
          <div
            v-for="(blog, index) in blogsData"
            :key="index"
            class="swiper-slide blog"
          >
            <div class="tag new-flag" v-if="blog.action === 'new'">
              <img src="@assets/icons/star.svg" alt="icon-top" class="icon-top mb-4 mr-3">
              <span class="has-text-weight-semibold has-text-blue"> NEW! </span>
              <img src="@assets/icons/star.svg" alt="icon-top" class="icon-bottom mt-4 ml-3">
            </div>
            <div class="blog-image">
              <div class="background-image" :style="`background: url('${blog.blogImgSrc}') center/cover no-repeat;`"></div>
            </div>
            <div class="blog-info is-flex is-align-items-center is-justify-content-space-between pl-2 pt-2">
              <div class="tag has-text-blue has-text-weight-semibold">{{blog.blogCategory}}</div>
              <div class="blog-date pr-2">{{blog.blogDate}}</div>
            </div>
            <div class="content pl-2 pt-3">
              <div class="blog-title has-text-weight-semibold">{{blog.blogTitle}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="arrows is-flex is-justify-content-center is-align-items-center">
        <img src="@assets/icons/arrow-left.svg" alt="arrow-left" :class="`arrow-left mr-4 ${prevEnabled}`" @click="slidePrev">
        <div v-for="element in blogsData.length"
            :key="element"
            :class="`bullet ml-2 mr-2 ${slideIndex === element ? 'active' : ''}`"></div>
        <img src="@assets/icons/arrow-right.svg" alt="arrow-right" :class="`arrow-right ml-4 ${nextEnabled}`" @click="slideNext">
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import { updateBlogFlags } from '@api/dashboard'

export default {
  data() {
    return {
      blogSwiper: undefined,
    }
  },
  computed: {
    slideIndex() {
      if (!this.blogSwiper) return
      return this.blogSwiper.activeIndex + 1
    },
    prevEnabled() {
      return this.slideIndex === 1 ? 'arrow-disabled' : 'is-clickable'
    },
    nextEnabled() {
      return this.slideIndex === this.blogsData.length ? 'arrow-disabled' : 'is-clickable'
    },
  },
  props: {
    blogsData: {
      type: Array,
      default: () => [],
      required: true
    },
  },
  methods: {
    initializeSwiper() {
      this.blogSwiper = new Swiper('.blogs-swiper-container', {
        initialSlide: 0,
        slidesPerView: 'auto',
      })
      this.blogSwiper.on('click', () => {
        setTimeout(() => {
          const isNew = this.blogsData[this.blogSwiper.realIndex].action === 'new'
          isNew && updateBlogFlags({
            blog_title: this.blogsData[this.blogSwiper.realIndex].blogTitle,
            action: 'viewed',
          }).then(() => {
            this.$emit('updateViewedBlog', this.blogSwiper.realIndex)
          })
          this.toBlog(this.blogsData[this.blogSwiper.realIndex].blogLink)
        }, 200)
      })
    },
    toBlog(url) {
      window.open(url)
    },
    slidePrev() {
      if (this.blogSwiper.isBeginning) return
      this.blogSwiper.slidePrev()
    },
    slideNext() {
      if (this.blogSwiper.isEnd) return
      this.blogSwiper.slideNext()
    },
  }
}
</script>

<style lang="scss" scoped>
.desktop-dashboard-blogs {
  height: 100%;
  .swiper-box {
    position: relative;
    height: 100%;
    .blogs-swiper-container {
      overflow: hidden;
      .swiper-wrapper {
        .swiper-slide.blog {
          .new-flag {
            height: 31px;
            z-index: 2;
            position: absolute;
            top: 5px;
            left: 5px;
            border-radius: 6px;
            text-align: center;
            span {
              font-size: 14px;
            }
          }
          .blog-image{
            overflow: hidden;
            .background-image{
              border-radius: 8px 8px 0px 0px;
              width: auto;
              height: 265px;
            }
          }
          .blog-info {
            .tag {
              height: 31px;
              border-radius: 6px;
            }
          }
          .blog-title {
            font-size: 14px;
            line-height: 20px;
          }
          .blog-date {
            font-size: 14px;
            color: #B6AECA;
          }
        }
      }
    }
    .arrows {
      z-index: 100;
      img{
        width: 20px;
        padding: 17px 4px;
        &.arrow-disabled{
          opacity: 0.5;
          pointer-events: none;
        }
      }
      .bullet {
        height: 6px;
        width: 6px;
        background-color: #F0EEF8;
        border-radius: 50%;
        display: inline-block;
        &.active {
          background-color: #9185AE;
        }
      }
    }
  }
}
</style>
