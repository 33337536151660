<template>
  <div class="desktop-dashboard">
    <div class="teaser-trigger is-clickable is-align-items-center" @click="toggleTeaser(true)" v-show="!showRecentInvestorsBanner && showRecentInvestorsBannerIcon">
      <img src="@assets/icons/polygon.svg" alt="polygon" class="polygon">
    </div>
    <div class="teaser-wrap" v-show="showRecentInvestorsBanner">
      <Teaser
        :investmentsData="investmentsBannerData"
        @toggleTeaser="toggleTeaser"
      ></Teaser>
    </div>
    <div class="central-column columns">
      <WelcomeMessage/>
      <div class="left-section column">
        <section class="setup-steps">
          <SetupSteps
            ref="setup"
            :profileSteps="profileSteps"
            :governmentIdStatus="governmentIdStatus"
            :hasAcceptedConfidentiality="hasAcceptedConfidentiality"
            :investorOnVeriffWatchlist="investorOnVeriffWatchlist"
          ></SetupSteps>
        </section>
        <div class="columns portfolio-and-blogs">
          <section class="column">
          <PortfolioOverview
            ref="portfolioOverview"
            :totalInvestments="totalInvestments"
            :investmentsDiversifications="investmentsDiversifications"
            @switchPortfolio="switchPortfolio"
          ></PortfolioOverview>
          </section>
          <section class="column ml-5">
            <Blogs
              :blogsData="blogsData"
              @updateViewedBlog="updateViewedBlog"
              ref="desktopBlogs"
            ></Blogs>
          </section>
        </div>
      </div>
      <div class="column wallet-overview">
        <WalletOverview
          :walletBalanceData="walletBalanceData"
        ></WalletOverview>
      </div>
    </div>

    <WelcomeOnboardModal
      :showFirstTimeMessage="showFirstTimeMessage"
      :bankAccountConnected="bankAccountConnected"
      @toggleFirstTimeMessage="toggleFirstTimeMessage"
      @toAddFund="toAddFund"
      @toLinkBankPage="toLinkBankPage"
    ></WelcomeOnboardModal>

    <MembershipRenewalModal
      :showMembershipExpiryModal="showMembershipExpiryModal"
      :inAvailableRegion="inAvailableRegion"
      @toggleMembershipExpiryModal="toggleMembershipExpiryModal"
    ></MembershipRenewalModal>

    <OwnersDayModal
      :distributionData="distributionData"
      @updateDistributionData="updateDistributionData"
    ></OwnersDayModal>
  </div>
</template>
<script>
import Teaser from '@components/teaser/investment-teaser.vue'
import WelcomeMessage from '@components/desktop/header-welcome-message.vue'
import SetupSteps from './Setup.vue'
import PortfolioOverview from './Portfolio.vue'
import Blogs from './Blogs.vue'
import WalletOverview from './WalletOverview.vue'
import WelcomeOnboardModal from '@components/dashboard-modals/welcome-onboard.vue'
import MembershipRenewalModal from '@components/dashboard-modals/membership-renewal.vue'
import OwnersDayModal from '@components/dashboard-modals/owners-day.vue'
import axios from '@lib/axios/middleware'
import { getInvestmentSummary } from '@api/portfolio'
import { getProfileSteps, fetchBlogData, getBalance, recentInvestmentsBanner } from '@api/dashboard'
import { getProfileDetails } from '@api/profile'
import { getInvestorData } from '@api/signup'
import { getBankAccountStatus } from '@api/wallet'
import { getInvestorFlags, getMembershipStatus } from '@api/common'
import { investorInAvailableRegion } from '@utils/common-methods/common'

export default {
  data() {
    return {
      totalInvestments: 0,
      investmentsDiversifications: {},
      blogsData: [],
      profileSteps: [],
      governmentIdStatus: '',
      hasAcceptedConfidentiality: false,
      walletBalanceData: {},
      showFirstTimeMessage: false,
      bankAccountConnected: false,
      membershipExpiryNotificationSeenAt: undefined,
      showMembershipExpiryModal: false,
      inAvailableRegion: true,
      distributionData: undefined,
      investmentsBannerData: {
        totalInvestorCount: 0,
        recentInvestments: [],
      },
      showRecentInvestorsBanner: false,
      showRecentInvestorsBannerIcon: false,
      investorOnVeriffWatchlist: false,
    }
  },
  components: {
    Teaser,
    WelcomeMessage,
    SetupSteps,
    PortfolioOverview,
    Blogs,
    WalletOverview,
    WelcomeOnboardModal,
    MembershipRenewalModal,
    OwnersDayModal,
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      axios.all([
        getInvestmentSummary(),
        getProfileSteps(),
        getProfileDetails(),
        fetchBlogData(),
        getInvestorData(),
        getBalance(),
        getBankAccountStatus(),
        getInvestorFlags(),
        getMembershipStatus(),
        recentInvestmentsBanner(),
      ]).then((result) => {
        if (result[0].success) this.handlePortfolioData(result[0].data.summary)
        if (result[1].success) this.profileSteps = result[1].data
        if (result[2].success) this.handleProfileData(result[2].data)
        if (result[3].success) this.handleBlogsData(result[3].data)
        if (result[4]) this.handleInvestorData(result[4])
        if (result[5].success) this.walletBalanceData = result[5].data
        if (result[6].success) this.bankAccountConnected = result[6].data.status
        if (result[7].success) this.handleInvestorFlags(result[7].data, result[8].data)
        if (result[8].success) this.handleMembershipStatusData(result[8].data)
        if (result[9].success) this.handleTeaserData(result[9].data)
      })
    },
    handlePortfolioData(data) {
      const { totalInvestments, investmentsDiversifications } = data
      this.totalInvestments = Number(totalInvestments)
      investmentsDiversifications && (this.investmentsDiversifications = investmentsDiversifications)
    },
    handleProfileData({ governmentIdStatus, hasAcceptedConfidentiality, investorOnVeriffWatchlist }) {
      this.governmentIdStatus = governmentIdStatus || 'TO-DO'
      this.hasAcceptedConfidentiality = hasAcceptedConfidentiality
      this.investorOnVeriffWatchlist = investorOnVeriffWatchlist
    },
    switchPortfolio(country, callback) {
      getInvestmentSummary(country.replace(/\s/g, '_')).then((res) => {
        callback()
        if (!res.success) return
        this.handlePortfolioData(res.data.summary)
      })
    },
    handleBlogsData(data) {
      this.blogsData = data
      this.$nextTick(() => {
        this.$refs.desktopBlogs && this.$refs.desktopBlogs.initializeSwiper()
      })
    },
    handleInvestorData(data) {
      localStorage.setItem('investor', JSON.stringify(data))
      this.showFirstTimeMessage = data.firstTimeOnMobileUi
      this.inAvailableRegion = investorInAvailableRegion(data.country, data.administrativeAreaLevel1)
    },
    updateViewedBlog(index) {
      this.blogsData[index].action = 'viewed'
    },
    toggleFirstTimeMessage(status) {
      this.showFirstTimeMessage = status
    },
    // the walletRoutes mixin has been imported to the setup component
    toAddFund() {
      this.$refs.setup.toAddFundsPage()
    },
    toLinkBankPage() {
      this.$refs.setup.toLinkBankPage()
    },
    handleInvestorFlags(data, membershipData) {
      this.membershipExpiryNotificationSeenAt = data.membershipExpiryNotificationSeenAt

      const { membershipPaid, membershipExpired } = membershipData
      if (data.distribution) {
        this.distributionData = {
          ...data.distribution,
          hasValidMembership: membershipPaid && !membershipExpired
        }
      }
    },
    handleMembershipStatusData({ membershipExpired }) {
      this.showMembershipExpiryModal = membershipExpired && !this.membershipExpiryNotificationSeenAt
    },
    toggleMembershipExpiryModal(status) {
      this.showMembershipExpiryModal = status
    },
    updateDistributionData(value) {
      this.distributionData = value
    },
    handleTeaserData(data) {
      this.investmentsBannerData = data
      this.showRecentInvestorsBannerIcon = data.recentInvestmentCount > 0
    },
    toggleTeaser(status) {
      this.showRecentInvestorsBanner = status
    },
  },
}
</script>

<style lang="scss" scoped>
.desktop-dashboard {
  .teaser-trigger {
    display: flex;
    position: fixed;
    z-index: 99;
    right: 0;
    top: 80px;
    width: 28px;
    height: 50px;
    background: #2D2926;
    border-radius: 12px 0px 0px 12px;
    opacity: 0.5;
    transition: width 1s;
    &:hover {
      width: 50px;
    }
    .polygon {
      width: 14px;
      margin-left: 8px;
    }
  }
  .teaser-wrap {
    position: fixed;
    right: 12px;
    top: 80px;
    z-index: 100;
  }
  .central-column {
    max-width: 1215px;
    margin: 0 auto;
    .left-section {
      flex: 1;
      padding-right: 40px !important;
      section {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
      }
      .setup-steps {
        margin-bottom: 40px;
      }
      .portfolio-and-blogs {
        height: 426px;
        section {
          height: 100%;
        }
      }
    }
    .wallet-overview {
      width: 375px;
      background: #F6F5FA;
      border-radius: 8px;
      flex-basis: unset;
      flex-grow: unset !important;
      flex-shrink: unset;
    }
  }
}
.columns {
  margin: 0;
  .column {
    padding: 0;
  }
}
</style>
