<template>
  <div class="transaction-history">
    <template v-if="transactions.length">
      <div class="transaction-block is-flex is-flex-direction-column" v-for="(transaction, index) in transactions" :key="index">
        <div class="is-flex is-align-items-center is-relative">
          <div :class="`icon is-flex is-justify-content-center is-align-items-center ${transaction.status === 'failed' ? 'del' : ''}`">
            <img :src="`https://addy-public.s3.us-west-2.amazonaws.com/transaction-type-${transaction.iconType}.svg`" alt="types">
          </div>
          <div :class="`details ${transaction.status === 'failed' ? 'del' : ''} ${fromDesktopDashboard ? 'is-smallwidth' : ''}`">
            <div class="transaction-name pb-1">{{transaction.title}}</div>
            <div
              v-if="transaction.property"
              :class="`transaction-property has-text-weight-semibold has-text-blue pb-1 ${!transaction.property ? '' : 'is-clickable'}`"
              @click="toPortfolio(transaction.property, transaction.hashid)"
            >{{transaction.property.nickname}}</div>
            <div class="hashid pb-1" v-if="!fromDesktopDashboard">ID: {{transaction.hashid}}</div>
            <div>{{transaction.issuedDate | formatDate}}</div>
          </div>
          <div class="right is-flex is-align-items-center">
            <div :class="`amount has-text-weight-semibold ${transaction.status === 'failed' ? 'del' : ''}`">
              {{transaction.txnType === 'Credit' ? '+' : '-'}} {{transaction.amount | currencyFormatter}}
            </div>
            <img :src="`https://addy-public.s3.us-west-2.amazonaws.com/transaction-status-${transaction.status}.svg`" alt="status">
          </div>
        </div>
        <div class="investment-actions is-flex is-flex-direction-column" v-if="showInvestmentActions(transaction)">
          <div class="line"></div>
          <div class="bottom-buttons is-flex is-flex-direction-row">
            <div
              class="has-text-weight-bold cancel-button is-flex is-justify-content-center is-align-items-center px-4 py-1 is-clickable"
              @click="openCancelConfirmation(transaction, index)"
            >
              Cancel {{getIconType(transaction.type)}}
            </div>
          </div>
        </div>
      </div>
      <div id="page-bottom-flag"></div>
      <div class="has-text-centered">
        <b-button loading v-show="isLoading"></b-button>
      </div>
      <div
        class="more is-flex is-justify-content-center is-align-items-center has-text-primary has-text-weight-semibold is-clickable"
        @click="toWallet"
        v-if="fromDesktopDashboard">View all</div>
    </template>
    <div v-else class="empty-state has-text-lavender has-text-centered pt-2">No transactions yet</div>
    <ContinueOrCancelModal
      :title="cancelConfirmationTitle"
      text="Are you sure you want to cancel this transaction?"
      continueButtonText="Yes"
      cancelButtonText="No"
      :showModal="showCancellationConfirmation"
      @cancelAction="showCancellationConfirmation = false"
      @doContinue="cancelTransaction"
    >
    </ContinueOrCancelModal>
    <ConfirmModal
      :title="errorModalTitle"
      :text="errorModalText"
      :showModal="showErrorModal"
      @closeModal="showErrorModal = false"
    >
    </ConfirmModal>
  </div>
</template>
<script>
import ContinueOrCancelModal from '@components/modal/continue-or-cancel-modal.vue'
import ConfirmModal from '@components/modal/confirm-modal.vue'
import { CountryMap } from '@utils/data/countries'
import { DateTime } from 'luxon'
import { mobileCheck } from '@utils/common-methods/common'
import { getPaginatedTransactions as getInvestorTransactions, cancelCoinOrder, cancelWithdrawal } from '@api/wallet'
import { getPaginatedTransactions as getEntityTransactions, cancelEntityCoinOrder, cancelEntityWithdrawal } from '@api/addy-plus/wallet'

export default {
  components: {
    ContinueOrCancelModal,
    ConfirmModal,
  },
  data() {
    return {
      transactions: [],
      pageNumber: 1,
      isLoading: false,
      loadedAllTransactions: false,
      showCancellationConfirmation: false,
      transactionToBeCancelled: undefined,
      indexToBeCancelled: undefined,
      showErrorModal: false,
      errorModalText: '',
    }
  },
  props: {
    fromDesktopDashboard: {
      type: Boolean,
      default: false,
    },
    walletCountry: {
      type: String,
      default: 'CA',
      validator(value) {
        return Object.keys(CountryMap).includes(value)
      },
    },
  },
  computed: {
    isMobile() {
      return mobileCheck()
    },
    cancelConfirmationTitle() {
      return !this.transactionToBeCancelled ? '' : `Cancel ${this.transactionToBeCancelled.type} ${this.transactionToBeCancelled.hashid}?`
    },
    errorModalTitle() {
      return !this.transactionToBeCancelled ? '' : `Unable to Cancel ${this.transactionToBeCancelled.type} ${this.transactionToBeCancelled.hashid}`
    },
    pageSize() {
      return this.fromDesktopDashboard ? 5 : 10
    },
    ownedByAddyBusiness() {
      return this.$route.path.startsWith('/addyPlus')
    },
    scrollElement() {
      return this.ownedByAddyBusiness ? document.querySelector('.addy-plus-body > main') : ((!this.isMobile) ? document.querySelector('#desktop-scroll-container') : window)
    },
    corporationId() {
      return this.$route.params.corporationId || ''
    },
  },
  created() {
    this.getTransactions(true)
  },
  beforeDestroy() {
    this.removeScrollEvent()
  },
  filters: {
    formatDate(date) {
      return DateTime.fromISO(date).toFormat('MMM d, yyyy')
    },
    currencyFormatter(value) {
      const number = Number(value)
      return number.toLocaleString('en-CA',
        { style: 'currency', currency: 'CAD' }
      )
    },
  },
  methods: {
    async getTransactions(initScrolling = false) {
      const params = {
        page: this.pageNumber,
        limit: this.pageSize,
      }
      if (!this.ownedByAddyBusiness) params.country = CountryMap[this.walletCountry].replace(/\s/g, '_')
      const data = await (this.ownedByAddyBusiness ? getEntityTransactions(this.corporationId, params) : getInvestorTransactions(params))
      this.isLoading = false
      if (!data.success) return

      if (initScrolling) this.transactions = []

      if (!data.data.transactions.length) this.loadedAllTransactions = true
      this.handleRawData(JSON.parse(JSON.stringify(data.data.transactions)))

      initScrolling && this.$nextTick(() => {
        this.addScrollingEvent()
      })
    },
    handleRawData(transactions) {
      transactions.forEach((transaction) => {
        transaction.iconType = this.getIconType(transaction.type)
        transaction.title = this.getTransactionTitle(transaction.type)
      })
      this.transactions = [...this.transactions, ...transactions]
    },
    getIconType(transactionType) {
      switch (transactionType) {
      case 'Investment Cancelled':
      case 'Investment':
        return 'investment'
      case 'Wallet Deposit':
        return 'deposit'
      case 'Wallet Withdrawal':
        return 'withdrawal'
      case 'Tip':
      case 'addyOne Membership':
      case 'Annual Membership':
      case 'Believer Membership':
      case 'Accredited Membership':
        return 'membership'
      case 'Distribution':
        return 'distribution'
      case 'Transfer Funds':
        return 'transfer'
      }
    },
    getTransactionTitle(transactionType) {
      if (!this.fromDesktopDashboard) return transactionType
      const transactionTitle = {
        'Investment': 'You copped a property',
        'Wallet Deposit': 'You deposited funds',
        'Wallet Withdrawal': 'You withdrew funds',
        'addyOne Membership': 'addyOne Membership',
        'Annual Membership': 'Annual Membership',
        'Believer Membership': 'Believer Membership',
        'Accredited Membership': 'Accredited Membership',
        'Distribution': 'You got a distribution'
      }
      return transactionTitle[transactionType] || transactionType
    },
    pullToAddData() {
      const element = document.querySelector('#page-bottom-flag')
      const position = element.getBoundingClientRect()

      if (position.top >= 0 && position.bottom <= window.innerHeight) {
        if (!this.isLoading && !this.loadedAllTransactions) {
          this.isLoading = true
          this.pageNumber++
          this.getTransactions()
        }
      }
    },
    addScrollingEvent() {
      if (this.fromDesktopDashboard || !this.transactions.length) return
      this.scrollElement.addEventListener('scroll', this.pullToAddData)
    },
    removeScrollEvent() {
      if (this.fromDesktopDashboard || !this.transactions.length) return
      this.scrollElement.removeEventListener('scroll', this.pullToAddData)
    },
    toWallet() {
      this.$router.push('/wallet')
    },
    showInvestmentActions(transaction) {
      return !this.fromDesktopDashboard &&
      (transaction.type === 'Wallet Withdrawal' || transaction.type === 'Wallet Deposit') &&
      transaction.cancelable
    },
    openCancelConfirmation(transaction, index) {
      this.transactionToBeCancelled = transaction
      this.indexToBeCancelled = index
      this.showCancellationConfirmation = true
    },
    async cancelTransaction() {
      const hashid = this.transactionToBeCancelled.hashid
      let res = null

      if (this.transactionToBeCancelled.type === 'Wallet Deposit') {
        res = await (this.ownedByAddyBusiness ? cancelEntityCoinOrder(this.corporationId, hashid) : cancelCoinOrder(hashid))
      } else {
        res = await (this.ownedByAddyBusiness ? cancelEntityWithdrawal(this.corporationId, hashid) : cancelWithdrawal(hashid))
      }
      this.handleCancellationResponse(res)
    },
    handleCancellationResponse(res) {
      this.showCancellationConfirmation = false
      const transaction = this.transactions[this.indexToBeCancelled]
      if (res.success) {
        transaction.status = 'failed'
      } else {
        transaction.status = this.getTransactionStatusFromError(res.error)
        this.handleCancellationError(res.error)
      }
      transaction.cancelable = false
      this.$emit('updateWalletCard')
    },
    getTransactionStatusFromError(err) {
      return err.includes('complete') ? 'complete' : 'pending'
    },
    handleCancellationError(err) {
      this.errorModalText = this.getErrorModalText(err)
      this.showErrorModal = true
    },
    getErrorModalText(err) {
      const errorMessage = {
        'error_cancelling_withdrawal': 'Unable to cancel withdrawal, please contact an administrator.',
        'error_cancelling_completed_withdrawal': 'Unable to cancel completed withdrawal.',
        'error_cancelling_settled_withdrawal': 'Unable to cancel withdrawal. 1 hour cancellation window has passed.',
        'error_cancelling_coin_order': 'Unable to cancel transaction, please contact an administrator.',
        'error_cancelling_completed_interac_coin_order': 'Unable to cancel completed transaction',
        'error_cancelling_processed_eft_coin_order': 'Unable to cancel transaction. Transaction is already processing.',
        'error_cancelling_settled_coin_order': 'Unable to cancel transaction. 1 hour cancellation window has passed.',
      }
      return errorMessage[err] ? errorMessage[err] : 'Unable to cancel transaction, please contact an administrator.'
    },
    toPortfolio(property, hashid) {
      if (!property) return
      this.$router.push(`/portfolio/${property.nameSpaceUrl}/property/${property.id}?corporationId=${property.corporationId}&distributionId=${hashid}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.transaction-history {
  .empty-state {
    font-size: 16px;
  }
  .transaction-block {
    padding: 10px 5px;
    margin-bottom: 12px;
    border-radius: 12px;
    background-color: #F0EEF8;
    .icon {
      width: 10%;
      >img {
        width: 32px;
        height: 32px;
        padding: 0 5px;
      }
    }
    .details {
      font-size: 16px;
      padding-left: 14px;
      width: 55%;

      .transaction-name {
        font-size: 20px;
      }
      .transaction-property {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

      }
      &.del div {
        color: #8a8886;
      }
      &.is-smallwidth {
        width: 170px;
      }
    }
    .right {
      height: 60px;
      width: 35%;
      justify-content: flex-end;
      .amount {
        font-size: 16px;
        &.del {
          text-decoration: line-through;
          color: #8a8886;
        }
      }
      >img {
        width: 32px;
        height: 32px;
      }
    }
    .investment-actions {
      padding-top: 10px;
      .line {
        margin: 0 10px;
        height: 1px;
        background-color: #D1C9E4;
      }
      .bottom-buttons {
        display: flex;
        padding-top: 10px;
        width: 60%;
        .cancel-button {
          height: 43px;
          width: 100%;
          margin-left: 54px;
          border: 1px solid #4A26AA;
          border-radius: 12px;
          font-family: 'Proxima Nova', sans-serif;
          font-size: 16px;
          color: #4A26AA;
        }
      }
    }
  }
  .more {
    font-size: 16px;
  }
  #page-bottom-flag {
    height: 2px;
  }
  .button.is-loading {
    border: none;
  }
}
</style>
