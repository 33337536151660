<template>
  <div class="recent-investors-banner is-relative">
    <img src="@assets/icons/close-primary.svg" alt="close" class="close is-clickable" data-testid="close" @click="toggleTeaser">
    <div class="has-text-weight-semibold total has-text-white has-text-centered">
      {{investmentsData.totalInvestorCount}} {{investmentsData.totalInvestorCount > 1 ? 'people' : 'person'}} recently invested!
    </div>
    <div
      class="has-text-white has-text-centered investor mb-2"
      data-testid="investor"
      v-for="(investment, index) in investmentsData.recentInvestments"
      :key="index"
    >
      {{investment.alias}} from
      <span class="location has-text-weight-semibold has-text-white">{{investment.city}}, {{investment.region}}</span>
      just invested
      <span class="amount has-text-weight-semibold has-text-white">{{investment.amount | currencyFormatter}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    investmentsData: {
      type: Object,
      default: () => {
        return {
          totalInvestorCount: 0,
          recentInvestments: [],
        }
      }
    }
  },
  computed: {
    investorAlias() {
      const options = ['Someone', 'A resident', 'A local']
      const index = Math.floor(Math.random() * 3)
      return options[index]
    },
  },
  filters: {
    currencyFormatter(value) {
      const number = Number(value)
      return number.toLocaleString('en-CA',
        { style: 'currency', currency: 'CAD' }
      )
    },
  },
  methods: {
    toggleTeaser() {
      this.$emit('toggleTeaser', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.recent-investors-banner {
  width: 143px;
  padding-bottom: 32px;
  background: rgba(45, 41, 38, 0.75);
  border-radius: 12px;
  .close {
    position: absolute;
    right: 15px;
    top: 12px;
  }
  .total {
    padding: 32px 16px 10px;
    font-size: 14px;
    line-height: 17px;
  }
  .investor {
    font-size: 14px;
    padding: 0 15px;
    line-height: 17px;
  }
}
</style>
