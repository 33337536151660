<template>
  <div class="desktop-dashboard-portfolio is-relative">
    <div class="switch-portfolio is-flex is-justify-content-center is-align-items-center is-clickable" @click="switchPortfolio">
      <span class="icon">
        <i class="fas fa-exchange-alt has-text-blue"></i>
      </span>
    </div>
    <div class="section-header has-text-weight-semibold has-text-centered">Investment Portfolio {{countryFlag}}</div>
    <div v-if="isLoading" class="pb-3">
      <b-skeleton width="40%" height="38px" position="is-centered"></b-skeleton>
    </div>
    <div v-else class="total-investment has-text-centered pb-3">
      <span class="symbol has-text-blue has-text-weight-semibold">$</span>
      <span class="value is-relative">
        <span class="has-text-blue">{{totalInvestments | formatNumber}}</span>
        <span class="currency has-text-blue has-text-weight-bold">{{countryCurrency}}</span>
      </span>
    </div>
    <div v-if="isLoading" class="graph-loading-skeleton is-relative pt-4">
      <b-skeleton circle width="50%" position="is-centered"></b-skeleton>
      <div class="flat">
        <b-skeleton width="80%" height="16px" position="is-centered"></b-skeleton>
      </div>
    </div>
    <template v-else>
      <section class="chart-container is-relative" v-if="totalInvestments">
        <div class="chart-title has-text-centered has-text-weight-semibold">Opportunity type diversification</div>
        <div id="chart" style="height: 100%;"></div>
      </section>
      <section class="portfolio-empty-state is-flex is-justify-content-center is-align-items-center" v-else>
        <div class="placeholder-text has-text-weight-semibold">Nothing here yet</div>
      </section>
    </template>
  </div>
</template>

<script>
import { CountryMap, CountryFlagMap, CountryCurrencyMap } from '@utils/data/countries'
import { formatter } from '@utils/common-methods/numberFormatter'
import * as echarts from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use([
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
])

export default {
  props: {
    totalInvestments: {
      type: Number,
      required: true
    },
    investmentsDiversifications: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: [],
      country: 'CA',
      isLoading: false,
    }
  },
  computed: {
    countryFlag() {
      return CountryFlagMap[this.country]
    },
    countryCurrency() {
      return CountryCurrencyMap[this.country]
    },
  },
  filters: {
    formatNumber(value) {
      return formatter(value)
    },
  },
  watch: {
    investmentsDiversifications() {
      this.isLoading = false
      this.totalInvestments && this.initChart()
    }
  },
  methods: {
    async initChart() {
      this.parseChartData()
      await this.$nextTick()
      const myChart = echarts.init(document.getElementById('chart'))
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          appendToBody: true,
          formatter: '{b} : CAD {c}.00 ({d}%)'
        },
        legend: {
          icon: 'circle',
          itemHeight: 18,
          bottom: 0,
          left: 'center'
        },
        series: [
          {
            type: 'pie',
            radius: ['48%', '70%'],
            center: ['50%', '40%'],
            label: {
              show: false,
              position: 'center'
            },
            data: this.chartData,
            roseType: 'radius',
            color: ['#F0EEF8', '#D1C9E4', '#B6AECA', '#9185AE'],
            emphasis: {
              itemStyle: {
                color: 'inherit',
              },
            },
          }
        ]
      })
    },
    parseChartData() {
      const array = []
      for (const key in this.investmentsDiversifications) {
        if (Object.hasOwnProperty.call(this.investmentsDiversifications, key)) {
          array.push({
            name: this.parsePropertyCategory(key),
            value: this.investmentsDiversifications[key]
          })
        }
      }
      this.chartData = array.sort(function(a, b) {
        return a.value - b.value
      })
    },
    parsePropertyCategory(category) {
      const map = {
        core: 'Core',
        corePlus: 'Core Plus',
        opportunistic: 'Opportunistic',
        valueAdd: 'Value-Add',
      }
      return map[category]
    },
    switchPortfolio() {
      if (this.isLoading) return

      this.isLoading = true
      this.country = this.country === 'CA' ? 'US' : 'CA'
      this.$emit('switchPortfolio', CountryMap[this.country], () => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.desktop-dashboard-portfolio {
  padding: 20px 0;
  height: 100%;
  .switch-portfolio {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 10px;
    right:  10px;
    border-radius: 6px;
    background: var(--addy-blue-light-2);
  }
  .section-header {
    margin-bottom: 16px;
    font-size: 16px;
  }
  .total-investment {
    .symbol {
      font-size: 20px;
      margin-right: 4px;
      vertical-align: top;
      line-height: 23px;
    }
    .value {
      font-size: 48px;
      line-height: 38px;
      .currency {
        font-size: 16px;
        position: absolute;
        right: -32px;
        top: 0;
      }
    }
  }
  .graph-loading-skeleton {
    height: calc(100% - 76px);
    > .b-skeleton {
      aspect-ratio: 1/1;
      .b-skeleton-item.is-rounded {
        height: 50%;
      }
    }
    .flat {
      position: absolute;
      width: 100%;
      bottom: 20px;
    }
  }
  .chart-container {
    height: calc(100% - 76px);
    .chart-title {
      width: 100px;
      font-size: 14px;
      position: absolute;
      top: 36%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .portfolio-empty-state {
    height: calc(100% - 76px);
    background: url('../../../assets/images/portfolio/portfolio-empty-state.svg') center/contain no-repeat;
    .placeholder-text {
      font-size: 16px;
      color: #9185AE;
      margin-top: -10px;
    }
  }
}
@media only screen and (max-width: 1150px) {
  .chart-container .chart-title {
    font-size: 12px !important;
  }
}
</style>
