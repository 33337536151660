<template>
  <StandardModal
    :showModal="showMembershipExpiryModal"
    :closeModal="closeMembershipExpiryModalAction"
    :buttonIsLoading="membershipButtonIsLoading"
    :buttonTappedAction="membershipButtonTappedAction"
    buttonTitle="Renew membership"
    title="Your membership has expired!"
    >
    <div class="modal-text has-text-centered">
      <div class="mb-4">You’ve been helping us enable everyone to invest in Canadian real estate for a whole year! 📈</div>
      <div class="mb-4">In order to continue investing in addys, please renew your membership. We’re excited to #InvestTogether.</div>
    </div>
  </StandardModal>
</template>
<script>
import StandardModal from '@components/modal/standard-modal.vue'
import { DateTime } from 'luxon'
import { updateInvestorFlags } from '@api/common'

export default {
  data() {
    return {
      membershipButtonIsLoading: false,
    }
  },
  props: {
    showMembershipExpiryModal: {
      type: Boolean,
      default: false,
    },
    inAvailableRegion: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    StandardModal,
  },
  methods: {
    closeMembershipExpiryModalAction() {
      this.updateMembershipExpiryFlag()
    },
    membershipButtonTappedAction() {
      this.membershipButtonIsLoading = true
      this.updateMembershipExpiryFlag(this.purchaseMembership)
    },
    updateMembershipExpiryFlag(callback) {
      updateInvestorFlags({
        membership_expiry_notification_seen_at: DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss')
      }).then((result) => {
        if (!result.success) return
        this.$emit('toggleMembershipExpiryModal', false)
        if (callback) callback()
      })
    },
    purchaseMembership() {
      if (!this.inAvailableRegion) {
        return this.$router.push({
          name: 'regionNotAvailable',
          query: { title: 'Become a member' }
        })
      }
      this.selectMembershipType()
    },
    selectMembershipType() {
      // returnUrl is used to differentiate the membership buy flow started from the portfolio page
      this.$router.push(`/membership/options?returnUrl=${encodeURIComponent('/dashboard')}`)
    },
  },
}
</script>
