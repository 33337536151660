<template>
  <StandardModal
    v-if="distributionData != undefined"
    modalClass="is-larger"
    class="owners-day"
    :showModal="distributionData != undefined"
    :closeModal="closeDistributionModalAction"
    :buttonTappedAction="distributionButtonTappedAction"
    :title="`${receivedDistribution ? 'Happy' : 'It\'s'} Owners' Day!`"
    titleClass="has-text-blue mb-0"
    :readMoreUrl="null"
    :buttonTitle="`${receivedDistribution ? 'See your return' : 'Start earning $$'}`"
  >
    <div class="modal-text owners-day-modal-text">
      <div class="sticker-wrap is-relative">
        <div class="background-explosion" v-if="readyForRender"></div>
        <div class="sticker-container is-flex is-justify-content-center is-align-items-center">
          <img :src="distributionData.miniPropStickerUrl" alt="mini prop sticker" class="mini-prop-sticker">
        </div>
      </div>
      <div
        v-if="readyForRender"
        class="x-clockwise-rotate has-text-blue has-text-weight-semibold has-text-centered"
        v-html="dynamicText"
      ></div>
      <AmountBreakDown
        v-if="readyForRender"
        :distributionData="distributionData"
      ></AmountBreakDown>
    </div>
  </StandardModal>
</template>
<script>
import StandardModal from '@components/modal/standard-modal.vue'
import { updateInvestorFlags } from '@api/common'
import { inAvailableRegion } from '@utils/common-methods/checkRegion'
import AmountBreakDown from '@components/distribution/amount-break-down.vue'

export default {
  data() {
    return {
      readyForRender: false,
    }
  },
  props: {
    distributionData: {
      required: true,
    },
  },
  watch: {
    distributionData(value) {
      value && setTimeout(() => {
        this.readyForRender = true
      }, 1500)
    },
  },
  computed: {
    receivedDistribution() {
      return !!Number(this.distributionData.ownedDistributionAmount)
    },
    crowd() {
      return this.distributionData.propertyCountry === 'Canada' ? 'Canadians' : 'Americans'
    },
    dynamicText() {
      return this.receivedDistribution ?
        `${this.distributionData.propertyNickname} just paid out ${this.distributionData.distributeesCount} ${this.crowd}` :
        `${this.distributionData.distributeesCount} owners of the ${this.distributionData.propertyNickname} just got paid! <br/> Don't miss out on the next one.`
    },
    hasValidMembership() {
      return this.distributionData.hasValidMembership
    },
    propertyId() {
      return this.distributionData.propertyId
    },
    corporationId() {
      return this.distributionData.corporationId
    },
    nameSpaceUrl() {
      return this.distributionData.propertyNameSpaceUrl
    },
  },
  components: {
    StandardModal,
    AmountBreakDown,
  },
  methods: {
    closeDistributionModalAction() {
      updateInvestorFlags({
        unlink_distribution_event: true,
      }).then((result) => {
        this.$emit('updateDistributionData', undefined)
      })
    },
    distributionButtonTappedAction() {
      this.closeDistributionModalAction()

      if (this.receivedDistribution) this.$router.push(`/portfolio/${this.nameSpaceUrl}/property/${this.propertyId}?corporationId=${this.corporationId}`)
      else if (!this.hasValidMembership) this.buyMembership()
      else this.$router.push('/allProperties')
    },
    buyMembership() {
      if (inAvailableRegion()) this.$router.push(`/membership/options?returnUrl=${encodeURIComponent('/dashboard')}`)
      else {
        this.$router.push({
          name: 'regionNotAvailable',
          query: { title: 'Become a member' }
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@keyframes x-clockwise-rotate {
  0% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes popup {
  0% {
    width: 0;
  }
  60% {
    width: 0;
  }
  94% {
    width: 240px;
  }
  100% {
    width: 200px;
  }
}

.sticker-wrap {
  height: 280px;
  margin-top: -20px;
  margin-bottom: -30px;
  .background-explosion {
    width: 100%;
    height: 100%;
    background: url('https://addy-public.s3.us-west-2.amazonaws.com/explosion_animation.gif') center center/contain no-repeat;
  }
  .sticker-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .mini-prop-sticker {
      width: 200px;
      animation: popup 2.5s ease-in;
    }
  }
}
.x-clockwise-rotate {
  animation: x-clockwise-rotate 1s ease-out;
}
</style>
