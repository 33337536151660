<template>
  <StandardModal
    :showModal="showFirstTimeMessage"
    :closeModal="closeOnboardingModalAction"
    :buttonIsLoading="onboardingCloseButtonIsLoading"
    :buttonTappedAction="onboardingButtonTappedAction"
    :buttonTitle="onboardingCloseButtontitle"
    :customModalContentClasses="['has-background-confetti']"
    title="Welcome to addy!"
    >
    <div class="modal-text has-text-centered">
      <div class="mb-5">Thanks for joining our mission of enabling every human to be a homeowner!</div>
      <div>Get started by
        {{ctaText}}
      </div>
    </div>
  </StandardModal>
</template>
<script>
import StandardModal from '@components/modal/standard-modal.vue'
import { updateInvestorProfile } from '@api/signup'
import { isAmericanUser } from '@utils/common-methods/common'

export default {
  data() {
    return {
      onboardingCloseButtonIsLoading: false,
    }
  },
  props: {
    showFirstTimeMessage: {
      type: Boolean,
      default: false,
    },
    bankAccountConnected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    onboardingCloseButtontitle() {
      if (this.isAmerican) return 'Get Started'
      return this.bankAccountConnected ? 'Add Funds' : 'Link Bank Account'
    },
    isAmerican() {
      return isAmericanUser()
    },
    ctaText() {
      if (this.isAmerican) return 'checking out our recent properties.'
      const canadianCTAText = this.bankAccountConnected ? 'adding some funds into your addy Wallet' : 'linking your bank account in order to fund your addy Wallet'
      return canadianCTAText + ' — let’s #InvestTogether.'
    },
  },
  components: {
    StandardModal,
  },
  methods: {
    closeOnboardingModalAction() {
      updateInvestorProfile({
        first_time_on_mobile_ui: false,
      })
      this.$emit('toggleFirstTimeMessage', false)
    },
    onboardingButtonTappedAction() {
      this.onboardingCloseButtonIsLoading = true
      updateInvestorProfile({
        first_time_on_mobile_ui: false,
      }).then(() => {
        this.onboardingCloseButtonIsLoading = false
        this.buttonAction()
      })
    },
    buttonAction() {
      if (this.isAmerican) return this.$router.push('/allProperties')
      return this.$emit(this.bankAccountConnected ? 'toAddFund' : 'toLinkBankPage')
    },
  },
}
</script>
