import { inAvailableRegion } from '@utils/common-methods/checkRegion'
import { doAccountVerification } from '@utils/common-methods/profileHelper'
import { getFeatureToggle } from '@api/common'

const dashboardCommonMethods = {
  data() {
    return {
      showVerificationModal: false,
      isVeriffEnabled: true,
    }
  },
  created() {
    this.fetchVeriffToggle()
  },
  computed: {
    steps() {
      if (!this.$isMobile && this.profileSteps.length % 2 === 1) {
        this.profileSteps.push({
          status: '',
          stepName: 'filler',
          timeExpectation: '',
          title: '',
        })
      }
      if (!inAvailableRegion()) {
        const stepsToCheckArray = new Set(['investment', 'membership_paid'])
        return this.profileSteps.map((step) => ({
          ...step,
          status: stepsToCheckArray.has(step.stepName) ? 'out-of-area' : step.status
        }))
      }
      return this.profileSteps
    },
  },
  methods: {
    fetchVeriffToggle() {
      getFeatureToggle('veriff_integration').then((res) => {
        if (res.success) this.isVeriffEnabled = res.data.enabled
      })
    },
    toStep({ stepName, status }, device) {
      if (status === 'out-of-area') return this.handleNotInRegion(stepName)
      if (stepName === 'email_verified') return this.handleVerifyEmail(status)
      if (this.noRedirect(status)) return
      if (stepName === 'link_bank_account') this.$router.push('/wallet/accounts/new')
      if (stepName === 'fund_wallet') return this.toAddFundsPage()
      if (stepName === 'membership_paid') return this.handleMembershipPurchase(device)
      if (stepName === 'account_verification') return this.handleAccountVerification(device)
      if (stepName === 'confidentiality') return this.handleConfidentialityAgreement(device)
      if (stepName === 'investment') return this.$router.push('/allProperties')
    },
    handleAccountVerification(device) {
      if (this.investorOnVeriffWatchlist) this.toggleVerificationModal(device)
      else doAccountVerification(this.isVeriffEnabled, this)
    },
    noRedirect(status) {
      return (status === 'complete' || status === 'pending' || status === 'processing')
    },
    handleNotInRegion(stepName) {
      this.$router.push({
        name: 'regionNotAvailable',
        query: { title: this.getTitle(stepName) }
      })
    },
    getTitle(stepName) {
      const stepTitle = {
        'link_bank_account': 'Add new bank',
        'fund_wallet': 'Fund wallet',
        'membership_paid': 'Become a member',
        'investment': 'Invest in a property'
      }
      return stepTitle[stepName]
    },
    handleVerifyEmail(status) {
      if (status === 'complete') return
      const path = inAvailableRegion() ? 'verify' : 'regionNotAvailable'
      const investorEmail = JSON.parse(localStorage.getItem('investor')).email
      this.$router.push(`/signup/${path}?email=${encodeURIComponent(investorEmail)}`)
    },
    handleMembershipPurchase(device) {
      if (device === 'mobile') this.mixpanel.track('xgzkptv6')
      this.selectMembershipType()
    },
    selectMembershipType() {
      // returnUrl is used to differentiate the membership buy flow started from the portfolio page
      this.$router.push(`/membership/options?returnUrl=${encodeURIComponent('/dashboard')}`)
    },
    handleConfidentialityAgreement(device) {
      if (this.hasAcceptedConfidentiality) return
      if (this.governmentIdStatus === 'verified') this.signAgreement()
      else this.toggleVerificationModal(device)
    },
    toggleVerificationModal(device) {
      this.showVerificationModal = !this.showVerificationModal
      if (device === 'mobile') {
        const pageHeader = document.querySelector('.menu-and-logo')
        const page = document.querySelector('.main-content')
        if (this.showVerificationModal) {
          pageHeader.style = 'z-index:2;'
          page.style = 'overflow: hidden;'
        } else {
          pageHeader.style = ''
          page.style = ''
        }
      }
    },
  }
}
export default dashboardCommonMethods
